import React, { useState, useRef, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ClipboardPlusFill, EraserFill, PlusCircle, XLg } from 'react-bootstrap-icons';
function App() {
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [files, setFiles] = useState(() => {
    const savedFiles = localStorage.getItem('files');
    return savedFiles ? JSON.parse(savedFiles) : [{ name: '', content: '' }];
  });
  const addFileRef = useRef(null);

  const handleDrop = (event, index) => {
    event.preventDefault();
    setDragOverIndex(null);
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newFiles = [...files];
        if (index === files.length) {
          newFiles.push({ name: file.name, content: e.target.result });
        } else {
          newFiles[index].name = file.name;
          newFiles[index].content = e.target.result;
        }
        setFiles(newFiles);
      };
      reader.readAsText(file);
    }
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleInputChange = (index, field, value) => {
    const newFiles = [...files];
    newFiles[index][field] = value;
    setFiles(newFiles);
  };

  const copyToClipboard = () => {
    const mainPrompt = document.getElementById('mainPrompt').value;
    const filenamesAndContent = files
      .filter(file => file.name.trim() !== '' && file.content.trim() !== '')
      .map(file => `Here is the content of a file ${file.name}:\n${file.content}`)
      .join('\n');

    const textToCopy = mainPrompt ? `${mainPrompt}\nHere are the filenames and their code:\n\n${filenamesAndContent}` : filenamesAndContent;

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setAlert({ show: true, message: 'Text copied to clipboard!', variant: 'success' });
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        setAlert({ show: true, message: 'Failed to copy text to clipboard!', variant: 'danger' });
      });
  };

  const clearFields = () => {
    document.getElementById('mainPrompt').value = '';
    setFiles([{ name: '', content: '' }]);
    localStorage.removeItem('mainPrompt');
    localStorage.removeItem('files');
  };

  const addFileRow = () => {
    setFiles([...files, { name: '', content: '' }]);
  };

  const removeFileRow = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  // Save files to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('files', JSON.stringify(files));
  }, [files]);

  // Load mainPrompt from localStorage on component mount
  useEffect(() => {
    const savedMainPrompt = localStorage.getItem('mainPrompt');
    if (savedMainPrompt) {
      document.getElementById('mainPrompt').value = savedMainPrompt;
    }
  }, []);

  // Save mainPrompt to localStorage whenever it changes
  const handleMainPromptChange = (event) => {
    const value = event.target.value;
    localStorage.setItem('mainPrompt', value);
  };

  return (
    <div className="App">
      {alert.show && (
        <Alert className="cursive-text-area" variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>
          {alert.message}
        </Alert>
      )}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className='logo-text'>
          Promptisimo
        </h1>
      </header>
      <div className='App-body'>
        <Container>
          <Row className='justify-content-center pt-4 mt-4'>
            <Col sm='12' >
              <Button variant="primary" onClick={copyToClipboard}>
                <ClipboardPlusFill/> Copy
              </Button>{' '}
              <Button variant="primary" onClick={clearFields}>
                <EraserFill/> Clear
              </Button>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col sm='12' >
              <div className="form-group pt-4 text-start">
                <label htmlFor="mainPrompt">Main Prompt</label>
                <textarea className="form-control my-text-area cursive-text-area" id="mainPrompt" rows="3" onChange={handleMainPromptChange}></textarea>
              </div>
            </Col>
          </Row>
          {files.map((file, i) => (
            <div
              className={`drop-area ${dragOverIndex === i ? 'drag-over' : ''}`}
              onDrop={(event) => handleDrop(event, i)}
              onDragOver={(event) => handleDragOver(event, i)}
              onDragLeave={handleDragLeave}
              key={i}
            >
              <Row className='justify-content-center file-row'>
                <Col sm='12' lg='4'>
                  <div className="form-group pt-4 text-start">
                    <label htmlFor={`file${i + 1}name`}>File {i + 1} name {i === 0 && '(drag+drop)'}</label>
                    <textarea
                      className="form-control my-text-area cursive-text-area"
                      id={`file${i + 1}name`}
                      value={file.name}
                      onChange={(e) => handleInputChange(i, 'name', e.target.value)}
                      rows="3"
                    ></textarea>
                  </div>
                </Col>
                <Col sm='12' lg='7'>
                  <div className="form-group pt-4 text-start">
                    <label htmlFor={`file${i + 1}content`}>File {i + 1} content</label>
                    <textarea
                      className="form-control my-text-area code-text-area"
                      id={`file${i + 1}content`}
                      value={file.content}
                      onChange={(e) => handleInputChange(i, 'content', e.target.value)}
                      rows="3"
                    ></textarea>
                  </div>
                </Col>
                <Col sm='1' className='text-center'>
                  <Button variant="danger" className="remove-btn" onClick={() => removeFileRow(i)}>
                    <XLg/>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row
            className={`justify-content-center mt-4 ${dragOverIndex === files.length ? 'add-file-drag-over' : ''}`}
            ref={addFileRef}
            onDrop={(event) => handleDrop(event, files.length)}
            onDragOver={(event) => handleDragOver(event, files.length)}
            onDragLeave={handleDragLeave}
          >
            <Col sm='12' className='text-center'>
              <Button variant="success" className="add-file" onClick={addFileRow}>
                <PlusCircle/> Add Another File
              </Button>
            </Col>
          </Row>
          <Row className='justify-content-center pt-4 mt-4 pb-4 mb-4'>
            <Col sm='12' >
              <Button variant="primary"  onClick={copyToClipboard}>
                <ClipboardPlusFill/> Copy
              </Button>{' '}
              <Button variant="primary" onClick={clearFields}>
                <EraserFill/> Clear
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
